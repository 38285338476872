.acc-ledger thead tr:first-child th{
    background: rgba(77, 48, 114, 1);
    color: white;
    /* padding: 1rem 2rem; */
    font-size: 13px;
   
}

.acc-ledger tbody th{
    background: rgba(77, 48, 114, 1);

}

.acc-ledger tbody td{
    padding: 0.5rem 2rem;
}

.acc-ledger-cont{
    margin-bottom: 0;
    height: 37.9rem;
    overflow: hidden;
    overflow-y: scroll;
}

.acc-ledger thead tr:first-child th{
    position: sticky;
    top: 0;
    z-index: 54;
}
.acc-ledger thead tr:nth-child(2) th{
    position: sticky;
    top: 3.5rem;
}

.acc-choose-head{
    background: #000;
}


.acc-choose thead th{
    background: #A4A1A1;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #A4A1A1;
    position: sticky;
    top: 0px;
}

.acc-choose tbody tr{
    border: 1px solid black;
}

.acc-choose-body{
    height: 26rem;
    overflow: hidden;
    overflow-y: scroll;
}

.choose-acc-btn{
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4A00A8;
}