.main-sheet-1 {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

.invoice-1 {
    width: 305mm;
    height: 229mm;
    padding: 10mm;
    margin: auto;
    border: 1px solid #ddd;
    box-shadow: 0 0 5mm rgba(0, 0, 0, 0.1);
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    page-break-after: always;
}
.s-head-1{
    height:25%;
    margin-left: 5px;
    margin-right:5px ;
}
.s-body-1{
    height:58%;
    margin-left: 5px;
    margin-right:5px ;
}
.s-foot-1{
    height:17%;
    margin-left: 5px;
    margin-right:5px ;
}


.main-sheet-1 .company-details-1 h5{
    margin:0;
    padding-bottom: 2px;
    text-transform: uppercase;
}
.main-sheet-1 .company-details-1 div{
    padding: 6px 0px 6px 0px;
    margin: 0;
}
.company-details-1 {
    margin-top: 10px;
    margin-bottom: 5px;
}

.all-details-1 {
    display: flex;
    justify-content: space-between;
}

.billing-details-1 h5{
    margin: 0;
}

.invoice-details-1 h5{
    margin: 0;
}

.invoice-details-1{
    text-align: right;
    padding-right: 20px;
}

.items-1 {
    overflow: visible;
}

.items-1 table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.items-1 th{
    padding: 2px;
    text-align: center;
    font-weight: bold;
    font-size: smaller;
}

.items-1 td {
    padding: 2px;
    padding-left: 3px;
    text-align: center;
    font-size:x-small;
}

.items-1 th:first-child{
    width:9%
}
.items-1 td:first-child{
    text-align: center;
}
.items-1 th:nth-child(2),.items-1 td:nth-child(2){
    width:46%;
    text-align: left;
}
.items-1 th:nth-child(6),.items-1 td:nth-child(6){
    width:20%
}

.totals-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.totals-1 strong{
    text-decoration: double underline;
}

.main-sheet-1 footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-center-1 {
    text-align: center;
}
