.sidebar {
  height: 100vh;
  width: 17rem;
  margin-right: 0 !important;
  position: sticky !important;
  top: 0px;
  background: linear-gradient(178.43deg, #2c0362 10.09%, #400091 80.48%);
}

/*150*/
@media (min-height: 450px){
  .sidebar{
    width: 11.5rem;
  }
  .SidebarItems{
    font-size: 1px;
    zoom: .6;
  }
  .SidebarItem{
    font-size: 15px !important;
    gap: 1 rem;
  }
  .SidebarItem.active{
    font-size: 15px;
  }
  .sidebar-items-button{
    font-size: 15px;
  }
  .sidebar_icon{
    width: 22px;
  }
  .trans-font-side-bar{
    font-size: 15
    px;
  }
  
}
/*133*/
@media (min-height: 500px){
  .sidebar{
    width: 13rem;
  }

  .SidebarItems{
    font-size: 1px;
    zoom: .7;
  }

  .SidebarItem{
    font-size: 15px !important;
    gap: 1 rem;
  }
  .SidebarItem.active{
    font-size: 15px;
  }
  .sidebar-items-button{
    font-size: 15px;
  }
  .sidebar_icon{
    width: 20px;
  }
  .trans-font-side-bar{
    font-size: 15px;
  }
}
/*120*/
@media (min-height: 600px){
  
  .sidebar{
    width: 14.5rem;
  }
  .SidebarItems{
    font-size: 1px;
    zoom: .8;
  }

  .SidebarItem{
    font-size: 15px !important;
    gap: 1 rem;
  }
  .SidebarItem.active{
    font-size: 15px;
  }
  .sidebar-items-button{
    font-size: 15px;
  }
  .sidebar_icon{
    width: 20px;
  }
  .trans-font-side-bar{
    font-size: 15px;
  }
}
/*110*/
@media (min-height: 650px){
  .sidebar{
    width: 16rem;
  }

  .SidebarItems{
    font-size: 1px;
  }

  .SidebarItem{
    font-size: 17px !important;
    gap: 1 rem;
  }
  .SidebarItem.active{
    font-size: 17px;
  }
  .sidebar-items-button{
    font-size: 17px;
  }
  .sidebar_icon{
    width: 18px;
  }
  .trans-font-side-bar{
    font-size: 17px;
  }
}

/*100%*/
@media (min-height: 700px){
  .sidebar{
    width: 17.3rem;
  }

  .SidebarItems{
    font-size: 1px;
    zoom: 1;
  }

  .SidebarItem{
    font-size: 14px !important;
    gap: 1 rem;
  }
  .SidebarItem.active{
    font-size: 14px;
  }
  .sidebar-items-button{
    font-size: 14px;
  }
  .sidebar_icon{
    width: 20px;
  }
  .trans-font-side-bar{
    font-size: 14px;
  }
}

/*90%*/
@media (min-height: 800px){
  .sidebar{
    width: 17.6rem;
  }
  .SidebarItems{
    font-size: 1px;
    zoom: 1.2;
  }

  .SidebarItem{
    font-size: 13px !important;
    gap: 1 rem;
  }
  .SidebarItem.active{
    font-size: 13px;
  }
  .sidebar-items-button{
    font-size: 13px;
  }
  .sidebar_icon{
    width: 18px;
  }
}

/* 80% */
@media (min-height: 900px){
  .sidebar{
    width: 21.5rem;
  }
  .SidebarItems{
    font-size: 1px;
    zoom: 1.2;
  }

  .SidebarItem{
    font-size: 15px !important;
    gap: 1 rem;
  }
  .SidebarItem.active{
    font-size: 15px;
  }
  .sidebar-items-button{
    font-size: 15px;
  }
  .sidebar_icon{
    width: 18px;
  }
}

/*70*/
@media (min-height: 1000px){
  .sidebar{
    width: 26rem;
  }
}

.sidebar.company {
  background: linear-gradient(178.43deg, #2c0362 10.09%, #400091 80.48%);
  mix-blend-mode: multiply;
}

.sidebar.permission {
  background: #233547;
}

.header-item {
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
  box-shadow: 0 0 5px 1px rgb(209, 209, 209);
}

.company-logo-cont {
  padding-top: 20px;
  z-index: 4;
  height: fit-content;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 0px;
  position: sticky;
}

.company-logo {
  height: 2rem;
  width: 80%;
  border-bottom: 2px solid transparent;
  border-image: radial-gradient(circle, rgb(255, 251, 251) 50%, #250055 80%);
  border-image-slice: 1;
}

.SidebarItems {
  overflow: hidden;
  height: 'fit-content';
  max-height: 90%;
  overflow-y: scroll;
  display: flex;
  justify-content: start;
  scrollbar-width: none;
  scrollbar-color: gray;
  flex-direction: column;
  justify-content: start;
  padding: 0 1rem !important;
}

.SidebarSpan {
  border-left: 1px solid white;
  color: white;
  width: fit-content;
}

/* .hr{
      height: 1.8rem;
      width: 1.4px;
      background-color: #468AEF;
  } */

.SidebarItemText {
  /* border: none;
  background: transparent;
  cursor: pointer;
  margin: none;
  width: fit-content;
  text-align: start; */
  display: flex;
  justify-content: start;
  align-items: start;
  padding-top: 0.4rem;
  color: white;
}

.SidebarItem {
  padding: 0.5rem 1.5rem;
  width: 100%;
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.04rem;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  /* filter: brightness(0) invert(1); */
}

/* .SidebarItem:hover{
      filter: grayscale(0);
      background-color: white;
  } */

.SidebarItem.active {
  color: black;
  background: #607be8;
}

.active .sidebar_icon {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.sidebar-checkbox{
  accent-color: #607be8;
  margin-right: 0.5rem;
  cursor: pointer;
  margin-top: 0.2rem !important;
}

.sidebar-items-button{
  text-align: start;
  margin: 0;
  padding: 0;
  vertical-align: top;
  background-color: transparent;
  border: none;
  color: white;
}

.sidebar-items-button.handle-key:hover{
  text-decoration: underline;
}