.bad-gateway-cont{
    width: 100%;
    height: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* gap: 1rem; */
    gap: 7rem;
    font-size: 10rem;
}

.bad-gateway-text{
    width: fit-content;
    height: fit-content;
}

.gateway-btn-cont{
    font-size: 25px;
    width: fit-content;
    height: fit-content;
    display: flex;
    /* flex-direction: column; */
    gap: 3rem;
}

.gateway-btn-cont .button{
    cursor: pointer;
    padding: 1rem 3rem;
    border-radius: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gateway-btn-cont .button.logout{
    color: white;
    background: #dc0029;
}
.gateway-btn-cont .button.go-back{
    color: white;
    background: #00dc28;
}