.PayrollTable thead tr th{
    border:0;
    background-color: rgba(77, 48, 114, 1);
    color: white;
    position: sticky;
    top: 0;
}

.PayrollTable tbody tr{
    border: 2px solid grey;
    
}

.PayrollTable tbody tr td {
  border:  0;
  padding: .7rem .7rem;
}

.PayrollTable tfoot tr td{
    border: 0;
    background-color: rgba(77, 48, 114, 1);
    color: white;
    position: sticky;
    bottom: 0;
}

.PayrollTable tbody input{
 border: 0 solid black;
 max-width:100px;
 text-align: center;
}

.rotate-in{
    transform: rotate(90deg);
}

.PayrollTable tr{
    height: .5rem;
} 

.PayrollTable thead tr th:first-child {
    width: 10rem;
}


/*150*/
@media (min-height: 450px){
    .zoom-responsive-view-payroll{
      zoom: .63;
    } 
    .payroll-height{
        height:45.5rem;
    }

    .payroll-table-height{
        height: 30rem !important;
    }

  }
  
  /*133*/
  @media (min-height: 500px){
    .zoom-responsive-view-payroll{
      zoom: .7;
    }
    .payroll-table-height{
        height: 31rem !important;
    }
  }
  
  /*120*/
  @media (min-height: 600px){
    .zoom-responsive-view-payroll{
      zoom: .78;
    }
    .payroll-table-height{
        height: 32rem !important;
    } 
  }
  
  /*110*/
  @media (min-height: 650px){
    .zoom-responsive-view-payroll{
      zoom: .86;
    }
  }
  
  /*100%*/
  @media (min-height: 700px){
    .zoom-responsive-view-payroll{
    zoom: .94;
    }
  }
  
  /*90%*/
  @media (min-height: 800px){
    .zoom-responsive-view-payroll{
      zoom: 1.03;
    }
  }
  
  /* 80% */
  @media (min-height: 900px){
    .zoom-responsive-view-payroll{
      /* height: 43rem; */
      zoom: 1.155;
    }
    .payroll-table-height{
        height: 33rem !important;
    } 
  }
  
  /*70*/
  @media (min-height: 1000px){
    .zoom-responsive-view-payroll{
      zoom: 1.4;
    }
    .payroll-table-height{
        height: 34rem !important;
    } 
  }