.dashboard .dashboard-center {
  background-color: rgba(31, 82, 175, 1);
  color: #4286a9;
  /* height: 25% !important; */
}

/* .dashboard-center .dashboard-center-left {
  width: 450px;
} */

.dashboard-center-left .grid-small-text {
  color: #373276;
  text-align: center;
  font-size: 9px;
}

.dashboard-center-left .grid-small-text2 {
  color: #373276;
  font-weight: bolder;
  text-align: center;
}

.dashboard-grid-radius1 {
  border-top-left-radius: 10px;
  border-right: 1px solid #373276;
  background-color: rgba(71, 115, 195, 1);
}

.dashboard-grid-radius2 {
  border-top-right-radius: 10px;
  background-color: rgba(71, 115, 195, 1);
}

.dashboard-grid-radius3 {
  border-bottom-left-radius: 10px;
  border-right: 1px solid #373276;
  border-top: 1px solid #373276;
  background-color: rgba(71, 115, 195, 1);
}

.dashboard-grid-radius4 {
  border-bottom-right-radius: 10px;
  border-top: 1px solid #373276;
  background-color: rgba(71, 115, 195, 1);
}

.dashboard-center .dashboard-center-right {
  background-color: rgba(44, 64, 101, 1);
  

 
}

.dashboard .dashboard-bottom .dashboard-bottom-box3 {
  box-shadow: 0px 0px 5px rgb(217, 195, 247);
  z-index: 10;
}

.dashboard-bottom .dashboard-bottom-box3-bottom {
  background-color: rgba(71, 115, 195, 1);
  position: sticky;
  bottom: 0;
}

.div-scroll {
  /* background-color: rgba(255, 255, 255, 0.469); */
  background-image: linear-gradient(rgba(255, 255, 255, 0.645),rgba(255, 255, 255, 0.303));
  height: 290px;
  overflow: auto;
}

.div-scroll::-webkit-scrollbar {
  display: none;
  width: 10px;
}

.div-scroll-cheque {
  /* background-color: rgba(255, 255, 255, 0.469); */
 
  height: 342px;
  overflow: auto;
}
.div-scroll-cheque .pdc-heading  {
  position: sticky;
  top:0
  
}
.div-scroll-cheque::-webkit-scrollbar {
  display: none;
  width: 10px;
}

.div-scroll p{
  font-size: 13px;
}


.dash-reciept-color{
 background-color: rgba(76, 76, 76, 1);
  color: white;
  box-shadow: 0px 0px 3px black;
  font-weight: 900;
  border-style: none;
 
}
.dash-reciept-color:hover{
  background-color: rgba(76, 76, 76, 1);
  color: white;
  box-shadow: 0px 0px 3px black;
  font-weight: 900;
  border-style: none;
}

.dash-payment-color{
  background-color: rgba(50, 80, 134, 1);
  color: white;
  box-shadow: 0px 0px 3px black;
  font-weight: 900;
  border-style: none;
 
}

.dash-payment-color:hover{
  background-color: rgba(50, 80, 134, 1);
  color: white;
  box-shadow: 0px 0px 3px black;
  font-weight: 900;
  border-style: none;
}

.dash-purchase-color{
  background-color: white;
  color: black;
  box-shadow: 0px 0px 3px black;
  font-weight: 900;
  border-style: none;
}
.dash-purchase-color:hover{
  color: black;
}

.dash-sales-color{
  background-color: white;
  color: black;
  box-shadow: 0px 0px 3px black;
  font-weight: 900;
  border-style: none;
}
.dash-sales-color:hover{
  color: black;
}

.dash-table-data{
  border-radius: 5px;
}

.dash-table-data td{
  text-align: start;
  font-size: 13px;
  

}
.dash-table-head th{
  text-align: start;
  font-size: 12px;
  background-color:rgb(189, 147, 243);

  position: sticky;
  top: 0;
}

.line-graph-canvas{
  margin-top: 2rem;
  width: 100% !important;
  height: 100% !important;
  max-height: 90% !important;
}


/*150*/
@media (min-height: 450px){
  .dashboard{
    height: 32.5rem !important;
    overflow: hidden;
    /* z-index: 55555555 !important; */
    
  }
  .zoom-responsive-view-dash{
    zoom: 1;
  }
  .dash-bun-top-rsp button{
    zoom: .5;
  }

  .icon-img-dash{
    zoom:1
  }

  .dashboard-center{
    
    height: 15rem!important;
   
  }
  .dashboard-center-left{
    height: 14rem !important;
  }
  .sd-div{
    height: 14rem !important;
    zoom: .5
  }
  .th-div{
    height: 1.5rem;
  }
  .dashboard-center-right{
    height: 14rem !important;
  }
  .line-graph-canvas{
    zoom: .6;
  }
  .dash-first-box{
    height: 15.9rem;
    zoom:.52;
  }
  /* .dashboard-bottom{
    height: 16rem;
  } */
  /* .dash-first-box-1{
    height: 24.5rem !important;
    
  } */
}

/*133*/
@media (min-height: 500px){
  .dashboard{
    height: 32.5rem !important;
    overflow: hidden;
    /* z-index: 55555555 !important; */
    
  }
  .zoom-responsive-view-dash{
    zoom: 1.12;
  }
  .dash-bun-top-rsp button{
    zoom: .55;
  }

  .icon-img-dash{
    zoom:1
  }

  .dashboard-center{
    
    height: 15rem!important;
   
  }
  .dashboard-center-left{
    height: 14rem !important;
  }
  .sd-div{
    height: 14rem !important;
    zoom: .55
  }
  .th-div{
    height: 1.5rem;
  }
  .dashboard-center-right{
    height: 14rem !important;
  }
  .line-graph-canvas{
    zoom: .7;
  }
  .dash-first-box{
    height: 15.9rem;
    zoom:.52;
  }
}

/*120*/
@media (min-height: 600px){
  .dashboard{
    height: 32.5rem !important;
    overflow: hidden;
    /* z-index: 55555555 !important; */
    
  }
  .zoom-responsive-view-dash{
    zoom: 1.25;
  }
  .dash-bun-top-rsp button{
    zoom: .55;
  }

  .icon-img-dash{
    zoom:1
  }

  .dashboard-center{
    
    height: 15rem!important;
   
  }
  .dashboard-center-left{
    height: 14rem !important;
  }
  .sd-div{
    height: 14rem !important;
    zoom: .55
  }
  .th-div{
    height: 1.5rem;
  }
  .dashboard-center-right{
    height: 14rem !important;
  }
  .line-graph-canvas{
    zoom: .7;
  }
  .dash-first-box{
    height: 15.9rem;
    zoom:.52;
  }

}

/*110*/
@media (min-height: 650px){
  .dashboard{
    height: 32.5rem !important;
    overflow: hidden;
    /* z-index: 55555555 !important; */
    
  }
  .zoom-responsive-view-dash{
    zoom: 1.37;
  }
  .dash-bun-top-rsp button{
    zoom: .55;
  }

  .icon-img-dash{
    zoom:1
  }

  .dashboard-center{
    
    height: 15rem!important;
   
  }
  .dashboard-center-left{
    height: 14rem !important;
  }
  .sd-div{
    height: 14rem !important;
    zoom: .55
  }
  .th-div{
    height: 1.5rem;
  }
  .dashboard-center-right{
    height: 14rem !important;
  }
  .line-graph-canvas{
    zoom: .5;
  }
  .dash-first-box{
    height: 15.9rem;
    zoom:.52;
  }
}

/*100%*/
@media (min-height: 700px){
  .dashboard{
    height: 32.5rem !important;
    overflow: hidden;
    /* z-index: 55555555 !important; */
    
  }
  .zoom-responsive-view-dash{
    zoom: 1.5;
  }
  .dash-bun-top-rsp button{
    zoom: .5;
  }

  .icon-img-dash{
    zoom:1.3
  }

  .dashboard-center{
    
    height: 15rem!important;
   
  }
  .dashboard-center-left{
    height: 14rem !important;
  }
  .sd-div{
    height: 14rem !important;
    zoom: .581
  }
  .th-div{
    height: 1.5rem;
  }
  .dashboard-center-right{
    height: 13.7rem !important;
  }
  .line-graph-canvas{
    zoom: .5;
  }
  .dash-first-box{
    height: 15.9rem;
    zoom:.52;
  }
}

/*90%*/
@media (min-height: 800px){
  .dashboard{
    height: 33rem !important;
    overflow: hidden;
    /* z-index: 55555555 !important; */
    
  }
  .zoom-responsive-view-dash{
    zoom: 1.64;
  }
  .dash-bun-top-rsp button{
    zoom: .55;
  }

  .icon-img-dash{
    zoom:1.2
  }

  .dashboard-center{
    
    height: 15rem!important;
   
  }
  .dashboard-center-left{
    height: 14rem !important;
  }
  .sd-div{
    height: 14rem !important;
    zoom: .6
  }
  .th-div{
    height: 1.5rem;
  }
  .dashboard-center-right{
    height: 14rem !important;
  }
  .line-graph-canvas{
    zoom: .7;
  }
  .dash-first-box{
    height: 15.9rem;
    zoom:.52;
  }
}

/* 80% */
@media (min-height: 900px){
  .dashboard{
    height: 33rem !important;
    overflow: hidden;
    /* z-index: 55555555 !important; */
    
  }
  .zoom-responsive-view-dash{
    zoom: 1.84;
  }
  .dash-bun-top-rsp button{
    zoom: .55;
  }

  .icon-img-dash{
    zoom:1.2
  }

  .dashboard-center{
    
    height: 15rem!important;
   
  }
  .dashboard-center-left{
    height: 14rem !important;
  }
  .sd-div{
    height: 14rem !important;
    zoom: .6
  }
  .th-div{
    height: 1.5rem;
  }
  .dashboard-center-right{
    height: 14rem !important;
  }
  .line-graph-canvas{
    zoom: .5;
  }
  .dash-first-box{
    height: 15.9rem;
    zoom:.53;
  }
}

/*70*/
@media (min-height: 1000px){
  .dashboard{
    height: 33rem !important;
    overflow: hidden;
    /* z-index: 55555555 !important; */
    
  }
  .zoom-responsive-view-dash{
    zoom: 2.24;
  }
  .dash-bun-top-rsp button{
    zoom: .55;
  }

  .icon-img-dash{
    zoom:1.2
  }

  .dashboard-center{
    
    height: 15rem!important;
   
  }
  .dashboard-center-left{
    height: 14rem !important;
  }
  .sd-div{
    height: 14rem !important;
    zoom: .6
  }
  .th-div{
    height: 1.5rem;
  }
  .dashboard-center-right{
    height: 14rem !important;
  }
  .line-graph-canvas{
    zoom: .5;
  }
  .dash-first-box{
    height: 15.9rem;
    zoom:.52;
  }
}