.bal-sheet-table-head{
    position: sticky;
    top: 0;
    /* z-index: 100; */
}
.bal-sheet-table-head th{
    background-color: rgba(77, 48, 114, 1);
    color: white;
    text-align: left;
}
.bg-liability-color td{
    background-color: rgb(234, 219, 242);
    position: sticky;
    top: 0px;
    text-align: left;
}
.bg-asset-color td{
    background-color: rgb(219, 242, 241);
    text-align: left;
}

.balance-sheet-btm-field{
    position: sticky;
    bottom: 0px;
   
}
.balance-sheet-btm-field td{
    background-color: rgb(80, 80, 89);
    color: white;
    text-align: left;
}

.gp-bal-table-btm {
    background-color: rgb(134, 98, 170);
}

.bal-sht-table-group-total td{
    background-color: rgb(186, 206, 223);
}
.bal-sht-table-btm td{
    background-color: rgb(77, 54, 98);
    color: white;
    position: sticky;
    bottom: 0px;
}

