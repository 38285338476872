div.stick{
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 4;
}
.accordion-item1 {
    cursor: pointer;
    position: relative;
}

.accordion-img1{
    position:absolute;
    top: 0;
    right: -1.6rem;
}

.move-cont{
    position:absolute;
    top: 0;
    left: 3rem;
    font-size: medium;
}
/* div.cont-border{
    border-left: 5px solid rgb(121, 120, 120);
    padding-left: 5px;
    border-radius: 5px;
} */

div.border-left{
    border-left: 5px solid #F5F5F5;
    padding-left: 5px;
}

div.time-cont{
    color: rgb(173, 170, 170);
    font-size: medium !important;
}

div.black-border{
    border:2px solid #5F7499;
    background-color:#5F7499;
    width:5px;
    border-radius:20px;
}

.cont-block{
    background-color: #F0EFFD;
    border-radius: 8px;
}

.demo-pic{
    width: 30px;
    height: 30px;
}

.activity-icon{
    width:60px;
    height:40px;
    border-radius: 10px;
}