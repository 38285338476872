.prev_btn {
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: black;
    border: 0;
    width: 4rem;
  }
  
  .white-to-black{
      -webkit-filter: invert(100%);
      filter: invert(100%);
  }
  
  .header {
    z-index: 1000;
    height: 3.5rem;
    top: 0;
    position: sticky;
  }

   /*150*/
   @media (min-height: 450px){
    .header{
      zoom: .6;
    }
    .heaader-user-cont{
      height: 50px;
      
    }
  }
  /*133*/
  @media (min-height: 500px){
    .header{
      zoom: .67;
    }
  }
  /*120*/
  @media (min-height: 600px){
    .header{
      zoom: .75;
    }
  }
  /*110*/
  @media (min-height: 650px){
    .header{
      zoom: .85;
    }
  }
  /*100*/
  @media (min-height: 700px){
    .header{
      zoom: .9;
    }
  }

   /*90*/
   @media (min-height: 800px){
    .header{
      zoom: 1;
    }
  }
   /*80*/
   @media (min-height: 900px){
    .header{
      zoom: 1.2;
    }
  }
  .header-cont {
    background: white;
    color: black;
    box-shadow: 2px 0px 5px 0.5px gray;
  }
  
  /* .search_bar {
    outline: none;
    position: relative;
    background: #4e4e4e;
    width: 10rem;
    padding-left: 1rem !important;
    padding-bottom: 0.3rem !important;
    color: white;
  }
  
  .seach_bar_cont {
    display: flex;
    align-items: center;
    background-color: #4e4e4e;
    padding: 0.1rem 0.4rem;
  } */
  
  @media print {
    #new {
      border: 1px solid black !important;
    }
  }
  

  .heaader-user-cont {
    background: #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 0.9rem !important;
    gap: 10%;
  }
  
  .header-user-prof-img {
    border-radius: 0.2rem;
    object-fit: cover;
    width: 3rem;
    height: 3rem;
    color: black;
  }
  
  .header-user-prof-img.company {
    width: 3.5rem;
    height: 3.5rem;
  }
  
  .header-role-text {
    color: gray;
    font-weight: 400;
    font-size: 0.6rem;
  }