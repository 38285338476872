@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.login-main-page-lh {
    background-image: linear-gradient(#47019F, #27005B);
}

.main-image-under-text {
    width: 50%;
    font-size: 19px;
    text-align: center;
}

.auth-input-field {
    width: 100%;
}

.btn-login,
.btn-login:hover {
    background-color: #47019F;
    color: white;
    width: 100%;
}

.btn-login.questions-add{
    color:#47019F;
    background: white;
    border: 1px solid #47019F;
}

.login-btm-border {
    border: solid #47019F 3px;
}

.otp-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #FFFFFF;
    border: 2px solid #757575;
}

.otp-field {
    width: 50px;
    height: 50px;
    border-style: none;
    text-align: center;
    font-size: 24px;
}

.otp-field:focus {
    outline: none !important;
}

.otp-bar {
    font-size: 40px;
    padding: 0px;
    color: #757575;
    font-weight: 100;
}

.span-text-color {
    color: #47019F;
    font-weight: 600;
}

.railway-font {
    font-family: "Raleway", sans-serif;
}

.otp-input {
    border: none;
    height: 55px;
    width: 100%;
    text-align: center;
    font-size: 24px;
    background: transparent;
}

.otp-input:focus {
    outline: none;
    border: none;
}

/* HTML: <div class="loader"></div> */
.login-loader {
    width: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid white;
    animation:
      l20-1 0.8s infinite linear alternate,
      l20-2 1.6s infinite linear;
  }
  @keyframes l20-1{
     0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
     12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
     25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
     50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
     62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
     75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
     100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
  }
  @keyframes l20-2{ 
    0%    {transform:scaleY(1)  rotate(0deg)}
    49.99%{transform:scaleY(1)  rotate(135deg)}
    50%   {transform:scaleY(-1) rotate(0deg)}
    100%  {transform:scaleY(-1) rotate(-135deg)}
  }

  .model-view{
    width: 200vb;
    height: 70vh;
  }

  .count-hr {
    flex-grow: 1;
    height: 1.5px;
    background-color: rgb(180, 180, 180);
    border: none;
}