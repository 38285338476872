
.table-scroll{
    height: 31rem;
    overflow-y: auto;
}

.stick-table table thead tr th{
    position: sticky;
    top: 0;
    z-index: 1;
}

.bord{
    border: red solid 1px !important;
}

.pur-btm td{
    background-color: rgb(71, 65, 65);
    color: white;
}
