.main-sheet-A4 {
    font-family: "Times New Roman", Times, serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.invoice-A4 {
    width: 210mm;
    height: 297mm;
    padding: 10mm;
    margin: auto;
    border: 1px solid #ddd;
    box-shadow: 0 0 5mm rgba(0, 0, 0, 0.1);
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    page-break-after: always;
}

.s-head-A4{
    height:40%
}
.s-body-A4{
    height:60% ;
    padding-left: .5%;
    padding-right: .5%;
}
.s-foot-A4{
    height:30%
}
.items-A4 {
    overflow: visible;
}

.items-A4 table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse; /* Ensure borders are collapsed for consistent alignment */
}

.items-A4 th, .items-A4 td {
    padding: 2px;
    text-align: left;
    font-size: x-small;
    border: 1px solid black; /* Add border to both th and td */
}

.items-A4 th {
    font-weight: bold;
    text-align: center;
    font-size: smaller;
}

.print-table-A4 {
    width: 100%;
    border-spacing: 0;
    overflow: hidden;
}

.print-table-A4 table {
    width: 100%;
    border-collapse: collapse; /* Ensure borders are collapsed for consistent alignment */
}

.print-table-A4 thead {
    display: table;
    width: calc(100%);
    table-layout: fixed;
}

.print-table-A4 th,.print-table-A4 td{
    border: 1px solid black; /* Add border to both th and td */
}
.print-table-A4 thead th{
    padding: 4px;
    word-break: break-word;
    white-space: normal;
    line-height: 10px;
    text-transform: uppercase;
    font-size:x-small;
 
}
.print-table-A4 thead td{
    padding: 4px;
    word-break: break-word;
    white-space: normal;
    line-height: 15px;
    text-transform: uppercase;
    font-size: smaller;
}

.print-table-A4 tbody {
    display: block;
}

.print-table-A4 tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.print-table-A4 tfoot tr {
    text-align: end;
}

.print-table-A4 tfoot td {
    padding-right: 60px;
    padding-bottom: 20px;
    text-decoration: double underline;
    font-size: small;
}

.print-table-A4 thead th:nth-child(1),
.print-table-A4 tbody td:nth-child(1) {
    width: 3.5%; 
    text-align: center;
}

.print-table-A4 thead th:nth-child(2),
.print-table-A4 tbody td:nth-child(2) {
    width: 25%;
}

.print-table-A4 thead th:nth-child(3),
.print-table-A4 tbody td:nth-child(3) {
    width: 5%;
}
.print-table-A4 thead th:nth-child(4),
.print-table-A4 tbody td:nth-child(4) {
    width: 5%;
}

.print-table-A4 thead th:nth-child(5),
.print-table-A4 tbody td:nth-child(5) {
    width: 8%;
}
.print-table-A4 thead th:nth-child(6),
.print-table-A4 tbody td:nth-child(6) {
    width: 8%;
}
.print-table-A4 thead th:nth-child(7),
.print-table-A4 tbody td:nth-child(7) {
    width: 4.5%;
    padding-left:0px ;
    padding-bottom: 0px;
}
.print-table-A4 thead th:nth-child(8),
.print-table-A4 tbody td:nth-child(8) {
    width: 7%;
}

.print-table-A4 thead th:nth-child(10),
.print-table-A4 tbody td:nth-child(10) {
    width: 4%;
    padding-left:0px ;
    padding-bottom: 0px;
}
