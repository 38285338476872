.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#myForm {
  transition: opacity 0.5s ease;
}

.purchase-input-label {
  color: #2c2c2c;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.form-control.purchase-input-text .icon{
  height: 100% !important;
  display: flex;
  align-items: center;
}

.form-control.purchase-input-text {
  text-transform: uppercase;
  height: 1.75rem;
  min-height: 1.75rem !important;
  background: white;
  border-radius: 0.3125rem;
  border: 1px solid #BEBEBE;
  font-size: 0.875rem;
}

.form-control.purchase-select {
  text-transform: uppercase;
  height: 1.75rem;
  min-height: 1.75rem !important;
  background: white;
  border-radius: 0.3125rem;
  border: 1px solid #BEBEBE;
  font-size: 0.875rem;
  min-width: 0.5rem !important;
}

.form-select.purchase-input-text {
  text-transform: uppercase;
  height: 1.75rem;
  min-height: 1.75rem !important;
  background: white !important;
  border-radius: 0.3125rem;
  border: 1px solid #BEBEBE;
  font-size: 0.875rem;
}
.form-select.purchase-input-text:focus {
  border: none !important;
  outline: 1px solid black;
  box-shadow: none;
}

.form-control.purchase-input-text:focus {
  outline: 1px solid black !important;
  box-shadow: none !important;
  border: none;
}

.form-control.purchase-input-text::placeholder {
  font-size: 0.875rem;
  text-align: center;
}

.form-control.purchase-input-text[type="number"] {
  -moz-appearance: none !important;
  appearance: none !important;
}

.form-control.purchase-input-text.mobile[type="number"] {
  -moz-appearance: textfield !important;
  -webkit-appearance: textfield !important;
  appearance: textfield !important;
}

.form-control.purchase-input-date {
  background: #000;
  color: #88ed7f;
  border-radius: 0.3125rem;
  height: 2.18rem;
}

.purch-table-btn1, .purch-table-btn1:hover{
  background: #5E50A3;
  color: white;
  border-radius:0.1rem ;
}

.table-secondary.purchase-table {
  --bs-table-color: #000;
  --bs-table-bg: #ffffff;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.purchase-table-header th {
  z-index: 3;
  position: sticky;
  top: 0;
  background: #3C8748;
  height: 0.4rem;
  text-align: center;
  font-size: 0.875rem;
  color: #fff;
  font-weight: 600;
  vertical-align: middle;
  padding: 0rem 1rem;
}

.purchase-table-body tr td {
  height: 1.75rem;
  text-align: center;
  font-size: 0.875rem;
  color: #2c2c2c;
  font-weight: 500;
  vertical-align: middle;
  /* padding-top: 0.09rem; 
    padding-bottom: 0.09rem; */
  padding: 0;
  padding-top: 0.3rem;
}

.purchase-table tfoot {
  display: table-footer-group !important;
}

.purchase-table-green {
  position: sticky;
  bottom: 0 !important;
}

.purchase-table-green td {
  padding: 0 !important;
  background: white;
}

.purch-green-table-item {
  background: #4d872a;
  height: 100%;
  margin-left: 1px;
  padding-top: 0.3rem !important;
}

.table-select-item {
  background: #c9c9c9;
}

.purchase-table-green td.item {
  border-radius: 0.125rem;
  border-bottom: none;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 600;
  height: 2rem !important;
}

.purchase-table-green td div .btn {
  font-size: 0.8rem !important;
  width: 100%;
  margin-right: 1px;
  margin-bottom: 0;
}

.purchase-detail-container {
  background: #E9FFEC;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25) inset;
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
  color: #2c2c2c;
  font-size: 0.875rem;
  font-weight: 500;
}

.purchase-supplier-container {
  background: white;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25) inset;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.purchase-supplier-container .container-title {
  font-size: 0.875rem;
  font-weight: 600;
}

.purchase-nav-btn {
  height: 2.188rem;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 600;
  background: #574f7d !important;
  border-radius: 0.5rem;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25) inset;
}

td.clr {
  color: #fc4040;
  font-size: 1rem;
  font-weight: 600;
}

td.n_clr {
  color: #46fc40;
  font-size: 1rem;
  font-weight: 600;
}

.purchase-nav-btn.select {
  background: #3c2a4d !important;
}

.table.purchase-item-table {
  border-radius: 0.3125rem;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
}

.table.purchase-item-table th:first-child {
  border-top-left-radius: 0.3125rem;
}

.table.purchase-item-table th:last-child {
  border-top-right-radius: 0.3125rem;
  border-right: none;
}

.table.purchase-item-table th {
  background: #191a19;
  color: #fff;
  height: 3.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-right: 1px solid #fff;
  line-height: 2rem;
  vertical-align: middle;
  text-align: center;
}

.purchase-table-item-container {
  background: white;
  height: 25.6rem;
  overflow: hidden;
  overflow-y: scroll;
}

.table.purchase-item-table td {
  height: 2.5rem;
  vertical-align: middle;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
}

.purchase-table-container {
  height: 30rem;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
}

.table.purchase-item-table thead {
  position: sticky;
  top: 0;
}

.table.purchase-item-table td.name {
  font-size: 0.875rem;
  text-align: start;
  font-weight: 500;
  color: #2c2c2c;
}

.purchase-table tbody tr td .purchase-table-items-input:focus {
  border: 0 !important;
  outline: 1px solid black;
}
.purchase-table tbody tr td .purchase-table-items-input {
  background: transparent;
  text-align: center;
  width: 100% !important;
  border: 0;
  font-size: 12px !important;
}

.purchase-table tbody tr.input-tr td {
  background: #a6cdec !important;
}

.purchase-add-btn {
  border-radius: 0.125rem;
  background: white;
  color: #000;
  border: 0;
  font-size: 1.64rem;
  font-weight: 900;
  padding: 0 0.5rem;
}

.btn-dark.purchase-edit-btn {
  border-radius: 0.125rem;
  background: #000;
  font-size: 0.85rem;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.purchase_search_drop .dropdown.icon {
  padding: 0 !important;
  top: 1rem !important;
}

.purchase_search_drop,
.purchase_search_drop input {
  display: flex !important;
  align-items: center !important;
  background: inherit !important;
  min-height: 1.6rem !important;
  color: black !important;
  font-size: 16px;
  font-family: inherit;
  text-transform: uppercase;
  min-width: 0 !important;
  border-radius: 0.2rem;
  padding: 2px 0 !important;
}
.purchase_search_drop input {
  font-size: 13px !important;
}

.ui.dropdown:not(.button) > .default.text {
  color: #000 !important;
}

.purchase_input {
  display: flex;
  align-items: center;
  background: inherit;
  color: inherit;
  font-family: inherit;
  border-radius: 0.2rem;
  padding: 2px 0;
}

.purchase_input:focus {
  outline: 1px solid black;
}

.outline-none {
  outline: none !important;
  border: none !important;
}

.table-item-add-btn:focus {
  /* border: 1px solid black; */
  background: #000 !important;
  color: white !important;
  border: none !important;
  outline: none !important;
}
.table-item-add-btn {
  border: none;
  font-size: 14px;
  font-weight: 700;
  background: inherit;
}
.table-item-add-btn2 {
  border: none;
  font-size: 18px;
  transform: rotate(45deg);
  font-weight: 600;
  background: inherit;
  align-items: start;
  display: flex;
}

.purchase-select input {
  color: #55585b;
  border-radius: inherit;
  font-size: 12px;
  left: 0px !important;
  text-transform: uppercase;
  width: inherit;
  height: 100% !important;
  padding: 0.2rem !important;
}

.purchase-select input:focus {
  outline: 1px solid black !important;
}

.purchase-select.selection .dropdown.icon {
  padding: 0 !important;
  margin-right: 0.1rem !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.purchase-batch-modal {
  min-height: 20rem;
  max-height: 20rem;
  background: #242424;
}

.table_cont {
  height: 16rem;
  overflow: hidden;
  overflow-y: scroll;
}

.table.purchase-serial-table {
  border-radius: 0.3125rem;
  background: #242424;
  margin-bottom: 0;
  border-bottom: #828181;
}

.table.purchase-serial-table th:first-child {
  border-top-left-radius: 0.3125rem;
}

.table.purchase-serial-table th:last-child {
  border-top-right-radius: 0.3125rem;
  border-right: none;
}

.table.purchase-serial-table th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: #191a19;
  color: #fff;
  height: 3.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-right: 1px solid #b5b5b5;
  border-bottom: none;
  vertical-align: middle;
  text-align: center;
}

.table.purchase-serial-table td {
  padding: 3px 1px;
  background: transparent;
  border-left: 1px solid #242424;
  margin: 0 0.2em;
  color: #fff;
  /* height: 2.25rem; */
  vertical-align: middle;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: black;
}

.table.purchase-serial-table td:first-child {
  border-radius: 0rem;
  background: inherit;
  border-left: none;
  color: white !important;
}

.table.purchase-serial-table td.name {
  font-size: 0.875rem;
  text-align: start;
  font-weight: 500;
  color: #2c2c2c;
}

.seriel-input {
  font-style: inherit;
  font-size: 0.875rem;
  text-transform: uppercase;
  caret-color: black !important;
  background: rgba(217, 217, 217, 0.5);
  padding: 0.325rem 0.5rem;
  border-radius: 0.3125rem;
  text-align: center;
  outline: none;
  border: none;
  width: 100%;
}

.seriel-input:focus {
  outline: 1px solid white;
}

.seriel-data {
  font-style: inherit;
  font-size: 0.875rem;
  text-transform: uppercase;
  caret-color: black !important;
  background: rgba(246, 4, 4, 0.5);
  padding: 0.1rem 0.5rem;
  border-radius: 0.3125rem;
  min-height: 1.6rem;
  outline: none;
  border: none;
  width: 100%;
}

.seriel-input-button:focus {
  outline: 1px solid white;
}
.seriel-input-button {
  border-radius: 0.3125rem;
  width: 1.4rem;
  background: #4a00a8;
  border: none;
  height: auto;
  color: white;
  font-weight: 1000;
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
}

.seriel-data-button {
  width: 1.8rem;
  border-radius: 0.3rem;
  border: none;
  background: white;
  color: black;
  padding: 0;
  padding-bottom: 0.2rem;
  font-weight: 800;
  font-size: 1.7rem;
  cursor: pointer;
}

.seriel-input:hover {
  cursor: context-menu;
}

.seriel-input.color-trnsp {
  background: transparent !important;
}

.purchase-bank-drop,
.purchase-bank-drop input {
  text-transform: uppercase !important;
  padding: 0 5px !important;
  min-height: 1.7rem !important;
  background: #eeeeee !important;
  display: flex !important;
  align-items: center !important;
}

.purchase-bank-drop.ui.selection.active.dropdown {
  outline: none !important;
  border: 1px solid black !important;
}

.purchase-bank-drop.ui.selection.active.dropdown .menu {
  outline: none !important;
  border: 1px solid black !important;
}

.purchase-bank-drop .icon {
  height: 1.7rem !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 !important;
  padding-right: 0.5rem !important;
}

#purchSaletableBodyTr td input::placeholder {
  color: black;
  opacity: 1;
}

#purchSaletableBodyTr td input:focus {
  border-radius: 0.1rem;
}

#purchSaletableBodyTr td input {
  padding: 1px;
  font-size: 13px;
}

.page_head.purchase-return {
  background: #7fff85 !important;
}

.page_head.purchase-order {
  background: #99588e !important;
}

#tableItemFkItem .text.divider{
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
}

#tableItemFkItem{
  height: 100% !important;
  max-height: 100% !important;
}

.purchase-footer-cash-credit-select,.purchase-footer-cash-credit-select:hover{
  position:relative;
  color: white;
  width: 100%;
  background: #214535;
  border: transparent;
  border-radius: 0.2rem;
  padding: 0.4rem 0.4rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* .purchase-footer-cash-credit-select::after{
  position: absolute;
  top: 3px;
  left: 5px;
  content: "a";
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: transparent transparent #ffffff transparent;
  background: red;
} */

.footer-button-save,.footer-button-save:hover{
  background: #29005D;
  color: white;
}

.footer-button-clear,.footer-button-clear:hover{
  background: white;
  color: #29005D;
  border: 1px solid #29005D;
}

.purchase-edit-popup{
  background: #3c8748;
  padding: 1rem;
  color: white;
}

#tr-input-width-data, #tr-input-width-data:focus{
  width: fit-content !important;
  background: transparent !important;
  border: none !important;
  cursor:default !important;
  outline: none !important;
  caret-color: transparent;
  color: white !important;
}



/*150*/
@media (min-height: 450px){
  .zoom-responsive-view-pur{
    zoom: .55;
  }
  .purchase-table-item-container{
    height: 27rem !important;
  }
  .purchase-table-body tr td{
    height: 2rem !important;
  }

  .column-resp-zoom{
    zoom: .58;
    height: 55rem !important;
  }
 
}
/*133*/
@media (min-height: 500px){
  .zoom-responsive-view-pur{
    zoom: .64;
  }
  .purchase-table-body tr td{
    height: 2.1rem !important;
  }
  .column-resp-zoom{
    zoom: .68;
    height: 53rem !important;
  }
  
}
/*120*/
@media (min-height: 600px){
  .zoom-responsive-view-pur{
    zoom: .7;
  }

  .purchase-table-body tr td{
    height: 4rem !important;
  }
  .purchase-table-item-container{
    height: 29rem !important;
  }
  .column-resp-zoom{
    zoom: .7;
    height: 57rem !important;
  }
}

@media (min-height: 650px){
  .zoom-responsive-view-pur{
    zoom: .82;
  }
  .column-resp-zoom{
    zoom: .78;
    height: 57rem !important;
  }
}

/*100%*/
@media (min-height: 700px){
  .zoom-responsive-view-pur{
  zoom: .85;
  }
  .purchase-table-item-container{
    height: 31.5rem !important;
  }
  .purchase-table-body tr td{
    height: 2.6rem !important;
  }
  .column-resp-zoom{
    zoom: .85;
    height: 57rem !important;
  }
}

/*90%*/
@media (min-height: 800px){
  .zoom-responsive-view-pur{
    zoom: .9;
  }
  .purchase-table-item-container{
    height: 34rem !important;
  }
  .purchase-table-body tr td{
    height: 2.85rem !important;
  }
  .column-resp-zoom{
    zoom: .9;
    height: 57rem !important;
  }
}

/* 80% */
@media (min-height: 900px){
  .zoom-responsive-view-pur{
    /* height: 43rem; */
    zoom: 1.1;
  }
  .purchase-table-item-container{
    height: 31rem !important;
  }
  .purchase-table-body tr td{
    height: 2.55rem !important;
  }
  .column-resp-zoom{
    zoom: 1.1;
    height: 57rem !important;
  }
}

/*70*/
@media (min-height: 1000px){
  .zoom-responsive-view-pur{
    zoom: 1.3;
  }
  .purchase-table-item-container{
    height: 32.3rem !important;
  }
  .column-resp-zoom{
    zoom: 1.3;
    height: 57rem !important;
  }
}