.company-cont {
  background: #ffffffa8;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 2px 5px rgb(227, 227, 227);
}

.company-list-cont {
  padding: 1rem;
}

.item_seach_bar_cont.admin {
  background: #f2f2f2 !important;
  height: 3rem;
}

.item_seach_bar_cont.admin .item_search_bar {
  background: transparent;
  color: black;
}

.company-add-btn,
.company-add-btn:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4A00A8;
  color: white;
}

.company-add-btn.clear {
  background: #707070;
}

.company-list-table-cont {
  height: 35rem;
  overflow: hidden;
  overflow-y: scroll;
}

/* .company-list.table tbody tr:hover td{
  background: gray;
  color: white;
} */

.company-list.table thead tr th {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #3d3b92;
  color: white;
  vertical-align: middle;
  outline: 1px solid white;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}

.company-list.table tbody tr td {
  position: relative;
  padding: 0 1rem 0 2rem;
  vertical-align: middle;
  text-align: start;
  height: 3rem;
  color: #4b4b4b;
  background: #f3f3f3;
  border-bottom: 1px solid white;
}

.company-list.table tbody tr td:after {
  content: "|";
  height: 80%;
  width: 1px !important;
  position: absolute;
  right: 0%;
  top: 10%;
  background-color: gray;
  color: gray;
}

.company-list.table tbody tr td:first-child {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.company-list.table tbody tr td:last-child {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.company-list.table tbody tr td:last-child:after {
  content: none;
}

.company-add-cont .company-progress-bar {
  padding: 0.8rem;
  position: relative;
  background: white;
  color: #37007e;
  border-bottom: 1px solid #4a04a5;
}

.company-progress-bar .bar-text {
  padding-left: 2rem;
  color: #4a04a5;
}

.company-progress-bar .bar-text.active {
  border-left: 1px solid rgb(209, 209, 209);
  color: white;
  font-weight: 600;
}

.company-progress-loader {
  border: transparent !important;
  width: 28%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: #4a04a5;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  /* animation-name: "bar-move1";
  animation-duration: 2s; */
  transition: width 1.2s ease-out 0s;
}

.company-progress-loader.act2 {
  width: 55%;
  /* animation-name: "bar-move2"; */
  transition: width 1.2s ease-out 0s;
}

.company-progress-loader.act3 {
  width: 90%;
  transition: width 1s ease-in;
}

.company-add {
  padding: 2rem;
  min-height: 35rem;
  padding-top: 0.5rem;
  padding-bottom: 0;
}

.comp-details-cont-1 {
  padding: 1rem;
  background: white;
}

.comp-details-cont-1 {
  padding: 1rem;
  background: white;
}

.comp-details-cont-1 .image-cont {
  width: 28rem;
  height: 8rem;
  background: #F5EEFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
}

.comp-details-cont-1 .image-upload-btn {
  font-weight: 600;
  border-radius: 0.3rem;
  margin-top: 1rem;
  background: #37007E;
  color: white;
  padding: 1rem;
  width: 28rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkbox-comp-details {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  accent-color: #37007E;
}

/* .checkbox-comp-details::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
} */

.comp-input-det {
  margin-top: 1.5rem;
}

.comp-input-det.active {
  background: #F2F2F2;

}

.circle {
  border-radius: 50%;
  background-color: deepskyblue;
  width: 13px;
  height: 13px;
  position: absolute;
  top: 34%;
  right: 18px;
}

/* .circle.animation1 {
  animation-delay: 4.5s;
} */

.circle.animation2 {
  animation: scaleIn 2s infinite;
  animation-delay: 1s;
}

.circle.animation3 {
  /* right: 13px; */
  animation: scaleIn2 2s infinite;
  animation-delay: 1s;
}

@keyframes scaleIn {
  0% {
    transform: scale(.2, .2);
    opacity: 0.7;
  }

  50% {
    transform: scale(1.7, 1.7);
    opacity: 0.7;
  }

  100% {
    transform: scale(.2, .2);
    opacity: 0.7;
  }
}

@keyframes scaleIn2 {
  40% {
    transform: scale(1.7, 1.7);
    opacity: 0.4;
  }

  50% {
    transform: scale(2.5, 2.5);
    opacity: 0.4;
  }

  80% {
    transform: scale(.2, .2);
    opacity: 0.4;
  }
}

.accordian-cont {
  max-height: 0px !important;
  overflow: hidden;
  overflow-y: scroll;
  /* animation: 'addition-accord' 1s; */
  transition: max-height 0.7s ease-out;
}

.accordian-cont.additional,.accordian-cont.login{
  max-height: 10rem !important;
  transition: max-height 0.7s ease-in;
}

@keyframes addition-accord {
  from {
    height: 0;
  }

  to {
    height: 10rem;
  }
}

.company-input-field.dropdown {
  border: 1px solid #adadad !important;
  border-bottom: 1.5px solid rgb(150, 150, 150) !important;
  background-color: #f5f5f5 !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: #616161 !important;
  cursor: text;
}
  .company-input-field {
  width: 100%;
}

.company-input-field div.MuiInputBase-root {
  border-bottom: 1px solid gray !important;
  background: #F5F5F5;
}

.cust-drop{
  border-bottom: 1px solid gray !important;
  background: #F5F5F5;
}

.company-plan-btn.module {
  user-select: none;
  background: #3C087E;
  border-radius: 0.4rem;
  color: white;
  padding: 0.5rem 1.5rem;
}

.module-cont {
  padding: 1rem 0;
  overflow: hidden;
  overflow-y: scroll;
  margin-top: 1rem;
  width: 35rem;
  column-gap: 2rem;
  row-gap: 1rem;
  height: 17rem;
  border-radius: 0.2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #F5EEFF;
  color: #808080;
}

.image-cont .company-details-company-logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.comp-plan-module-modal {
  width: 100%;
  /* padding: 1rem; */
  display: flex;
  flex-direction: column;
}

.comp-plan-module-modal .module-items {
  width: 100%;
  padding: 1.5rem 0rem;
  min-height: 20rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: start;
}

.comp-module-item {
  cursor: pointer;
  user-select: none;
  height: 8rem;
  width: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 0.3rem;
  border: 1px solid #7E48C2;
  /* color: gray; */
  background: #F7F1FF;
}

.comp-module-item.active {
  color: white;
  background: linear-gradient(166.42deg, #5A13B6 8.84%, #3A077A 85.91%);
}

.comp-module-item.active img {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}


.comp-module-btn {
  background: linear-gradient(166.42deg, #5A13B6 8.84%, #3A077A 85.91%);
  color: white;
  width: fit-content;
  margin: 1rem;
}

.comp-module-btn.previous {
  background: gray !important;
}

.action-bar {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.action-bar-item {
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  font-weight: 600;
  color: gray;
}

.action-bar-item.active {
  color: #4A00A8;
  border: 1px solid #4A00A8
}

.permission-cont {
  height: 33rem;
  overflow: hidden;
  overflow-y:scroll ;
  display: flex;
  width: 100%;
  background: #F5F5F5;
}

.permission-cont .permission-list2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 1rem;
  column-gap: 3.5rem;
}

.permission-list3 {
  font-size: 17px;
  display: flex;
  flex-direction: column;
  flex-direction: start;
  align-items: start;
  gap: 1rem;
}

.permission-list4 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  font-size: 12px;
}

.check-all-permission{
  width: 0.2rem;
}

.permission-checkbox-cont {
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  border: 1px solid #C9C9C9;
  color: #3F3F3F;
}

.permission-checkbox-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.permission-checkbox {
  display: inline-block;
  width: 2em;
  height: 2em;
  background-color: white;
  accent-color: #4A00A8;
  border-radius: 50%;
  border: 1px solid #4A00A8;
  appearance: none;
  vertical-align: middle;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.permission-checkbox:checked:before {
  border-radius: 50%;
  content: '\2713';
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
}

.permission-checkbox:checked {
  border: 1px solid transparent;
  background: #4A00A8;
  color: white;
}

.company-edit-bar {
  background: linear-gradient(90deg, #5B13B7 0%, #32046C 100%);
  color: white;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.5rem;
  align-items: center;
}

.company-edit-bar .bar-text{
  font-size: 17px;
}

.company-edit-bar .bar-button{
  width: fit-content;
  background: #3E66CD;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-size: 12px;
  border-radius: 0.2rem;
  cursor:pointer;
  gap: 0.6rem;
}

.company-edit-bar img{
  width: fit-content;
  margin-left: 0.5rem;
}

.company-view1 img{
  width: 15rem;
  height: 10rem;
  min-width: 15rem;
  min-height: 10rem;
  object-fit: contain;
}

.company-view-cont{
  padding: 1rem 2.4rem;
}

.company-view{
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 0.4rem;
  padding: 1rem 2rem;
}

.company-view .comp-det{
  row-gap: 1.5rem;
}

.comp-view-modules{
  height: 17rem;
  overflow: hidden;
  overflow-y: scroll;
}

.comp-view.table {
  border: none;
}

/* .comp-view.table thead.main-head tr{
  background: linear-gradient(90deg, #5B13B7 0%, #32046C 100%) !important;
  border-radius: 0.2rem 0 !important;
} */
.comp-view.table thead.main-head tr th{
  /* background: transparent; */
  background: #3D0881;
  text-align: center;
  color: white;
  padding: 0.8rem 0;
}

.comp-view.table tbody tr td {
  background: #F5F5F5;
  padding: 0;
  padding-top: 0.3rem;
  border: none;
}

.comp-view.table tbody tr.active td{
  background: #F9F9F9;
}

.comp-view.table tbody tr td:first-child{
  padding-left: 0.5rem !important;
}
.comp-view.table tbody tr td:last-child{
  padding-right: 0.5rem !important;
}

.comp-view.table tbody tr:last-child td{
  padding-bottom: 0.3rem;
}

.comp-view.table tbody tr td .comp-view-td{
  padding: 0.6rem 0;
  vertical-align: middle;
  background: #FFFFFF;
  height: 2.5rem;
}

.comp-view.table .comp-view-arrow{
  cursor: pointer;
  padding: 0;
}

.comp-view.table .comp-view-arrow.active{
  /* animation: 'compArrRotate' 1s; */
  transform: rotate(180deg);
}

/* .comp-view.table .comp-view-arrow.inactive{
  animation-direction: reverse;
}

@keyframes compArrRotate {
  0%{
    transform: rotate(0);
  }
  100%{
    transform: rotate(180deg);
  }
} */

.comp-view.table tbody tr.main-tr.active td .comp-view-td{
  background: transparent;
}

.comp-view.table .inner-table thead tr th{
  color: #795D9C;
  background: transparent;
  border: none;
}

.comp-view.table .inner-table tbody.inner-tbody tr td{
  background-color: #F5F5F5;
}

.accordian-anim{
  overflow: hidden;
  overflow-y: scroll;
  max-height: 0px;
  transition: max-height 0.5s ease-out;
}

.accordian-anim.active{
  max-height: 30rem;
  transition: max-height 0.5s ease-in;
}

/* @keyframes acc-anim {
  from{
    height: 0;
    max-height: 0;
  }
  to{
    max-height: 3rem;
    height:max-content
  }
} */

.company-menue-dropdown{
  position: absolute;
  top:36px;
  z-index: 5;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background: white;
  padding: 1rem;
  border-radius: 0.6rem;
}

.purchase-input-text.table-drop.custom-drop-wid {
  min-width: 30% !important; 
  /* max-width: 100% !important;      */
  margin-left: .8rem;
  margin-right: .8rem;
  padding-top: 0;
  padding-bottom: 0;
  border-color: rgb(170, 170, 170) !important;
}

.comp-plan-module-modal .module-select-all.btn{
  background-color: #32046C;
  color: white;
  padding: 0.4rem 3rem;
  font-size: 16px;
  font-weight: 600;
  width: fit-content;
}


.comp-plan-module-modal .module-select-all.btn.clear{
  border: 1px solid #32046C;
  color: #32046C;
  background: white;
}