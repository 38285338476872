.daybook-table.table thead th {
  position: sticky;
  top: 0;
  background: rgba(77, 48, 114, 1);
  color: white;
  padding: 0.6rem 0.9rem;
  font-weight: 500;
  text-align: start;
}

.daybook-table.table tbody td {
  text-align: start;
  /* padding: 0.6rem 0.9rem; */
}

.daybook-table.table tr.foot td {
  background: #d2c5e3;
}

.daybook-table.table tr.foot td {
  vertical-align: middle;
  /* padding: 0.7rem 3rem; */
  text-align: center;
}

.daybook-foot-data {
  background: white;
  border-radius: 0.5rem;
  padding: 0.2rem;
  vertical-align: middle;
  display: inline-block;
  width: 50%;
}

.day-book-table-cont {
  
  height: 35rem;
  overflow: hidden;
  overflow-y: scroll;
}
/*150*/
@media (min-height: 450px){
  .zoom-responsive-view{
    zoom: .6;
  }
}
/*133*/
@media (min-height: 500px){
  .zoom-responsive-view{
    zoom: .67;
  }
}
/*110*/
@media (min-height: 600px){
  .zoom-responsive-view{
    zoom: .73;
  }
}

@media (min-height: 650px){
  .zoom-responsive-view{
    zoom: .82;
  }
}

/*100%*/
@media (min-height: 700px){
  .zoom-responsive-view{
  zoom: .9;
  }
}

/*90%*/
@media (min-height: 800px){
  .zoom-responsive-view{
    zoom: 1;
  }
}

/* 80% */
@media (min-height: 900px){
  .day-book-table-cont{
    /* height: 43rem; */
    zoom: 1.2;
  }
}

/*70*/
@media (min-height: 1000px){
  .day-book-table-cont{
    height: 53rem;
  }
}

.daybook-table thead tr th {
  padding: 0.3rem 0.6rem;
}

.daybook-table tbody tr td {
  padding: .5rem;
}

.loading-cont-par-blur{
  filter: blur(2.5px);
  pointer-events: none;
}
.loading-cont-par-blur *{
  pointer-events: none;
}

.loader-container{
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  width: 100% !important;
  height: 31rem;
  max-height: 31rem;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 105px;
  aspect-ratio: 1;
  background: transparent !important;
  position: relative;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  border-radius: 50px;
  background: transparent !important;
  box-shadow: 0 0 0 3px inset pink;
  animation: l4 2.5s infinite;
}
.loader:after {
  animation-delay: -1.25s;
}
@keyframes l4 {
  0% {
    inset: 0 60px 60px 0;
  }
  12.5% {
    inset: 0 60px 0 0;
  }
  25% {
    inset: 60px 60px 0 0;
  }
  37.5% {
    inset: 60px 0 0 0;
  }
  50% {
    inset: 60px 0 0 60px;
  }
  62.5% {
    inset: 0 0 0 60px;
  }
  75% {
    inset: 0 0 60px 60px;
  }
  87.5% {
    inset: 0 0 60px 0;
  }
  100% {
    inset: 0 60px 60px 0;
  }
}



/* .table-height-response-set{
  height: 800px !important;
} */