.custom-table-2 tbody tr td, .custom-table-2 tbody tr th{
    height: fit-content;
    padding: 0 !important;
}

.unit_modal_body {
    height: 25rem;
    overflow: hidden;
    overflow-y: scroll;
}



/*150*/
@media (min-height: 450px){
    .zoom-responsive-view-acc{
      zoom: .6;
      height: 53.5rem !important;
      
    }
    .table-scroller{
        height: 50rem !important;
    }
    .response-customer-zoom{
        zoom: .9;
    }
    .response-customer-zoom td{
        font-size: 15px;
    }

    .ac-code-chg-model{
      zoom: .6;
    }

    .set-rate-model-customer{
      zoom: .7;
      /* width: 60rem !important; */
    }
  }
  /*133*/
  @media (min-height: 500px){
    .zoom-responsive-view-acc{
      zoom: .67;
    }
    .ac-code-chg-model{
      zoom: .68;
    }
    .set-rate-model-customer{
      zoom: .78;
    }
  }
  /*120*/
  @media (min-height: 600px){
    .zoom-responsive-view-acc{
      zoom: .73;
    }
    .ac-code-chg-model{
      zoom: .7;
    }
    .set-rate-model-customer{
      zoom: .89;
    }
  }
  /* 110 */
  @media (min-height: 650px){
    .zoom-responsive-view-acc{
      zoom: .82;
    }
    .ac-code-chg-model{
      zoom: .78;
    }
    .set-rate-model-customer{
      zoom: .95;
    }
  }
  
  /*100%*/
  @media (min-height: 700px){
    .zoom-responsive-view-acc{
    zoom: .9;
    }
    .ac-code-chg-model{
      zoom: .85;
    }
    .set-rate-model-customer{
      zoom: 1;
    }
  }
  
  /*90%*/
  @media (min-height: 800px){
    .zoom-responsive-view-acc{
      zoom: 1;
    }
    .ac-code-chg-model{
      zoom: .95;
    }
    .set-rate-model-customer{
      zoom: 1.2;
    }
  }
  
  /* 80% */
  @media (min-height: 900px){
    .zoom-responsive-view-acc{
      /* height: 43rem; */
      zoom: 1;
    }
    .ac-code-chg-model{
      zoom: 1.1;
    }
    .set-rate-model-customer{
      zoom: 1.4;
    }
  }
  
  /*70*/
  @media (min-height: 1000px){
    .zoom-responsive-view-acc{
        zoom: 1.3;
    }
    .ac-code-chg-model{
      zoom: 1.4;
    }
    .set-rate-model-customer{
      zoom: 1.6;
    }
  }