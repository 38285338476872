.stock-journal-report-table-cont table tbody tr td{
    background: white;
    color: black;
}

.stock-journal-report-table-cont .table thead tr th{
    background: rgba(77, 48, 114, 1);
    color: white;
    padding: 0.5rem 0.6rem;
}

.stock-journal-report-table-cont{
    height: 30.5rem;
    overflow: hidden;
    overflow-y: scroll;
}

.stock-journal-report-table-cont .table tbody tr td.top-tr{
    background: #BD93F3;
    color: #FFF;
    
}

.stock-journal-report-table-cont .table tbody tr td.bottom-tr{
    background: #B1B1B1;
}

.stock-journal-report-table-cont .table tbody tr td.bottom-tr .input{
    background: #FFF;
    height: 2.1875rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 3rem; */
}