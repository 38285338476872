th,td{
    padding: .5rem 0rem;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
}


.outstanding-table thead th{
    z-index: 4;
    position: sticky !important;
    background-color: rgba(77, 48, 114, 1);
    top: 0 !important;
}

.outstanding-table tbody td{
    z-index: 3;
    border-bottom: solid rgba(0, 0, 0, 0.47) 1px;
}

/* .toggle_data tbody td{
    z-index: 4 !important;
} */


/* .border-out-rp tr td {
    border: solid !important;
    color: blue !important;
} */
