.main-sheet {
    font-family: "Times New Roman", Times, serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.invoice {
    width: 165mm;
    height: 230mm;
    /* width: 148mm;
    height: 210mm; */
    padding: 10mm;
    margin: auto;
    border: 1px solid #ddd;
    box-shadow: 0 0 5mm rgba(0, 0, 0, 0.1);
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    page-break-after: always;
}
.s-head{
    height:20%
}
.s-body{
    height:65% 
}
.s-foot{
    height:15%
}

.main-sheet header {
    border-bottom: 1px solid grey;
}
.main-sheet .company-details h5{
    margin:0;
    padding-bottom: 2px;
    text-transform: uppercase;
}
.main-sheet .company-details div{
    padding: 8px 0px 8px 0px;
    margin: 0;
}
.company-details {
    margin-top: 10px;
    margin-bottom: 10px;
}

.all-details {
    display: flex;
    justify-content: space-between;
}

.billing-details h5{
    margin: 0;
}

.invoice-details{
    text-align: right;
}

.items {
    overflow: visible;
}

.items table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.items table, .items th, .items td {
    border: 1px solid #ddd;

}

.items th{
    padding: 2px;
    text-align: center;
    font-weight: bold;
    font-size: smaller;
}

.items td {
    padding: 2px;
    padding-left: 3px;
    text-align: left;
    font-size:x-small;
}

.items th:first-child{
    width:9%
}
.items td:first-child{
    text-align: center;
}
.items th:nth-child(2),.items td:nth-child(2){
    width:46%
}
.items th:nth-child(6),.items td:nth-child(6){
    width:20%
}

.totals {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.totals strong{
    text-decoration: double underline;
}

.main-sheet footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-center {
    text-align: center;
}


/* @media print {
    body * {
        visibility: hidden;
    }
    .invoice, .invoice * {
        visibility: visible;
    }
    .invoice {
        page-break-after: always;
    }
    .items {
        overflow: visible;
    }
    .items table, .items th, .items td {
        page-break-inside: avoid;
    }
} */