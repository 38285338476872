.profile{
    height: 40rem;
    width: 100%;
    overflow: hidden;
    box-shadow: 0px 0px 6px 0px #00000040;
    position: relative;
    padding: 2rem;
    padding-top: 6rem;
    display: flex;
}

.prof-top{
    left: 0;
    background: #431E72;
    height: 30%;
    width: 100%;
    position: absolute;
    top: 0;
}

.prof-tab-cont{
    position: sticky;
    top: 0;
    background: white;
}

.prof-cont1{
    position: relative;
    height: 100%;
    border: 1px solid #C09DEB;
    width: 25%;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-top: 4.5rem;
    flex-direction: column;
    padding-bottom: 3rem;
}

.prof-cont1-top{
    position: absolute;
    top: 0;
    height: 30%;
    width: 100%;
    left: 0;
    background: #2C1050;
}

.prof-user-icon{
    width: 8rem;
    height: 8rem;
    z-index: 5;
}

.prof-cont2{
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
    border: 1px solid #C09DEB;
    background: #FFFFFF;
    width: 100%;
    padding-bottom:2rem !important;
}

.prof-tab{
    cursor: pointer;
    font-size: 19px;
    font-weight: 600;
    padding-bottom: 0.2rem;
    width: fit-content;
}

.prof-tab.active{
    border-bottom: 3px solid #4A00A8;
}

.prof-pill-buttons{
    width: 12rem;
    font-size: 17px;
    margin-top: 1rem;
    gap: 0.5rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0;
    flex-direction: column;
    border: 1px solid #C1C1C1;
    font-family: Roboto;
    border-radius: 0.4rem;
}

.prof-edit-btn{
    width: fit-content;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    gap: 1rem;
    border: 1px solid #BEBEBE;
    border-radius: 0.5rem;
    align-items: center;
}

.prof-det-field1{
    padding-top: 2rem;
    gap: 3rem;
    display: flex;
    width: 100%;
    /* flex-wrap: wrap; */
    justify-content: space-between;
}

.prof-pass-cont{
    max-height:5.5rem;
    padding: 0.6rem 1rem;
    transition: max-height 0.4s ease-out;
    overflow: hidden;
}

.prof-pass-cont.active{
    border: 1px solid #BEBEBE;
    border-radius: 0.4rem;
    max-height: 30rem;
    transition: max-height 0.4s ease-in;
}

.prof-pass-change-btn{
    display: flex;
    gap: 3rem;
    border-radius: 0.4rem;
    padding: 0.5rem 1rem;
    width: fit-content;
    height: fit-content;
    border: 1px solid #BEBEBE;
}

.prof-pass-change-submit-btn{
    background: #431E72;
    color: white;
    padding: 0.7rem 1.5rem;
    width: fit-content;
    border-radius: 0.4rem;
}

.prof-pass-change-btn:hover{
    background: #431E72;
    color: white;
}

.image-upload-btn.profile {
    font-weight: 600;
    border-radius: 0.3rem;
    background: #37007E;
    color: white;
    padding: 1rem;
    width: 12rem;
    height: 2.5rem;
    margin-left: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

.can-btn{
    background: white;
    color: 37007E; 
    padding: 1rem;
    width: 9rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 0.3rem;
    border: 1px solid #a3a3a3;
}

.save-btn{
    background: #37007E;
    color: white; 
    padding: 1rem;
    width: 9rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 0.3rem;
}

.acs{
    z-index: 4;
}
