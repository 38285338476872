#sheet{
    width: 210mm;
}
/* .body-content {
    min-height: 120mm;
} */

/* .table-header {
   height: 10px;
   line-height: 20px;
} */

tr.table-row{
    height: 20px;   
    line-height: 20px;
}

.print-header{
    height: 10% !important;
}

.company-details {
    line-height: 0.1 !important;
}
/* 
.print-table{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 400px; //comment
} 
.print-body,tbody.print-body{
    height: 450px !important;
}
.print-foot{
    display:flex;
    justify-content: end;
    margin-bottom: 5px;
}
.print-head,.print-body,.print-foot{
    width: 100%;
}

.print-table thead th, .print-table tbody td{
    min-width: 50px;
    border: .1px solid gray;
    background-color: aqua;
} */


.print-table {
    width: 100%;
    max-height: 1000px;
    border: 1px solid #dee2e6;
    border-spacing: 0;
    overflow: hidden;
}

.print-table table {
    width: 100%;
    border-collapse: collapse;
}

.print-table thead{
    display: table;
    width: calc(100% - 18px); /* Adjust for scrollbar */
    table-layout: fixed;
}

.print-table thead th{
    height: 30px;
    line-height: 20px;
    text-align: center;
    padding: 2PX;
    border: 1px solid #dee2e6;
    text-transform: uppercase;
    font-size: smaller;
    font-weight: bold;
}

.print-table tbody {
    display: block;
    height: 500px;
    width: 100%;
}

.print-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.print-table tbody td {
    text-align: start;
    padding: 8px;
    padding-left:0PX;
    border: 1px solid #dee2e6;
    word-wrap: normal;
    white-space: normal;
    line-height: 15px;
    text-transform: uppercase;
    font-size: smaller;
    /* overflow: hidden; */
}

.print-table thead,.print-table tfoot,.print-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.print-table tfoot tr{
    text-align:end;
}
.print-table tfoot td{
    padding-right:60px;
    padding-bottom: 20px;
    text-decoration: double underline;
}
.print-table thead th:nth-child(1) {
    width:20px
}
.print-table tbody td:nth-child(1) {
    width:20px
}
.print-table thead th:nth-child(2) {
    width:130px
}
.print-table tbody td:nth-child(2) {
    width:131px;
    word-wrap: break-word;
}

.print-table thead th:nth-child(3) {
    width:40px
}
.print-table tbody td:nth-child(3) {
    width:40px
}

.print-table thead th:nth-child(4) {
    width:30px
}
.print-table tbody td:nth-child(4) {
    width:30px
}
.print-table thead th:nth-child(6) {
    width:70px
}
.print-table tbody td:nth-child(6) {
    width:70px
}
.print-table thead th:nth-child(7) {
    width:40px
}
.print-table tbody td:nth-child(7) {
    width:40px;
    text-align:center
}
.print-table thead th:nth-child(10){
    width:30px
}
.print-table tbody td:nth-child(10) {
    width:30px;
    text-align:center
}
.print-table thead th:nth-child(11) {
    width:50px
}
.print-table tbody td:nth-child(11) {
    width:50px
}

.print-table thead th:nth-child(13) {
    width:80px
}
.print-table tbody td:nth-child(13) {
    width:80px
}