.normalA4-print.table tbody th tr td{
    border: 0px !important;
}

.sales-bill{
    width: fit-content;
    max-width: fit-content;
}

.normal-a4 .print-header{
    height: 7rem;
}

.print-normala4-2-img-cont{
    height: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.print-normala4-2-img{
    width: 100%;
    height: 100%;
}


.thermal-print .brdr-top{
    border: 0px !important;
    border-top: 1px solid rgb(59, 59, 59) !important;
    border-style: dashed !important;
}
.thermal-print .brdr-btm{
    border: 0px !important;
    border-bottom: 1px solid rgb(59, 59, 59) !important;
    border-style: dashed !important;
}

.thermal-print .brdr-btm-top{
    border: 0px !important;
    border-bottom: 1px solid rgb(59, 59, 59) !important;
    border-top: 1px solid rgb(59, 59, 59) !important;
    border-style: dashed !important;
}