.op-stock-det{
    background: #ffff;
    border-radius: 0.2rem;
    padding: 0.4rem 1rem;
    box-shadow: 0px 0px 5px 2px lightgray !important;
}

.op-stock-apply-btn,.op-stock-apply-btn:hover{
    background: #29005D;
    height: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
}

.op-stock.table thead th{
    position: sticky;
    top: 0;
    background: #4D3072;

    border:none;
    color: white;
    padding: 0.4rem 0.4rem;
    font-size: 14px;
    text-align: center;
}
.op-stock.table tbody td input{
    border: none;
    font-size: 13px;
    text-align: start;
    text-align: center;
    padding: 0.3rem 0.4rem;
    border-radius: 0.2rem;
    width: 100%;
}

.op-stock.table tbody td input:focus{
    outline:1px solid #000;
}

.op-stock-table-cont{
    height: 20rem;
    border: 1px solid gray;
    border-top: none;
    overflow: hidden;
    overflow-y: scroll;
}

.op-stock.table{
    position: relative;
    border-top: none;
}

.purchase-input-text.op-stock-dropdown .search-dropdown .dropdown{
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
}

.purchase-input-text.op-stock-dropdown{
    height: 1.7rem !important;
    padding: 0 !important;
}

.purchase-input-text.op-stock-dropdown .search-dropdown .dropdown .icon{
    right: 2rem;
}
.purchase-input-text.op-stock-dropdown .search-dropdown .dropdown .text{
    padding: 0 0.7rem 0 0.7rem !important;
}
.purchase-input-text.op-stock-dropdown .search-dropdown .dropdown input.search{
    background-color: #EEE !important;
    border: 1px solid #dcdcdc !important; 
    height: 1.7rem !important;
    padding: 0 0.7rem 0 0.7rem !important;
}


/*150*/
@media (min-height: 450px){
    .response-opt-stock{
      zoom: .6;
      /* height: 55rem !important; */
    }
    .op-stock-table-cont{
        height: 22rem;
    }
  }
  /*133*/
  @media (min-height: 500px){
    .response-opt-stock{
      zoom: .63;
    }
    .op-stock-table-cont{
        height: 27rem;
    }
  }
  /*120*/
  @media (min-height: 600px){
    .response-opt-stock{
      zoom: .7;
    }
    .op-stock-table-cont{
        height: 27rem;
    }
  }
  
  /*110*/
  @media (min-height: 650px){
    .response-opt-stock{
      zoom: .78;
    }
    .op-stock-table-cont{
        height: 27.5rem;
    }
  }
  
  /*100%*/
  @media (min-height: 700px){
    .response-opt-stock{
    zoom: .85;
    }
    .op-stock-table-cont{
        height: 28rem;
    }
  }
  
  /*90%*/
  @media (min-height: 800px){
    .response-opt-stock{
      zoom: .92;
    }
    .op-stock-table-cont{
        height: 29rem;
    }
  }
  
  /* 80% */
  @media (min-height: 900px){
    .response-opt-stock{
      zoom: 1.05;
    }
  }
  
  /*70*/
  @media (min-height: 1000px){
    .response-opt-stock{
      zoom: 1.3;
      /* height: rem; */
    }
  }