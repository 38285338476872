.account-select-dropdown{
    border: none;
    background: #565656;
    border-radius: 0.3125rem;
    color: #FFF;
    width: 100%;
    height: 1.7rem;
    padding-left: 0.75rem; 
}

.account-gray-btns{
    background: #565656;
    color: white;
}

.account-select-black-accent{
    accent-color: black !important;
}

.account-table-container{
    background: #EEE;
    border-radius: 0.3125rem;
    padding: 0 0;
    height: 27rem !important;
    box-shadow: 0px 0px 6px 0px rgba(91, 91, 91, 0.18) inset;
    overflow-y: scroll;
    overflow-x: hidden;
}

.table.account-group-table th{
    font-family: 'Raleway';
    background: #4D3072;
    font-size: 0.975rem;
    font-weight: 500;
    color: #FFF;
    padding-left: 0rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.table.account-group-table th:first-child{
    border-top-left-radius: 0.3125rem;
    padding-left: 0.875rem;
}

.table.account-group-table th:last-child{
    border-top-right-radius: 0.3125rem;
    width: 25%;
}

.table.account-group-table td{
    background: #EEE;
    font-size: 0.975rem;
    font-weight: 500;
    color: #2C2C2C;
    padding: 0.25rem 0rem;
}

.table.account-group-table td:first-child{
    padding-left: 0.3rem;
}

.table.account-group-table td:last-child{
    padding-right: 0.3rem;
}

.table.account-group-table td .content:focus{
    border: none !important;
    box-shadow: none !important;
    outline: 1px solid black !important;
     
}

.table.account-group-table td .content{
    text-transform: uppercase;
    background: #FFF;
    width: 100%;
    border: 0;
    padding: 0.25rem 0.25rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
}

.table.account-group-table td .content .form-select-sm{
    background-position: right .25rem center;
    padding-left: 0.25rem;
    padding-right: 1.5rem;
}

.table.account-group-table td:first-child .content{
    padding-left: 0.5rem;
}

.table.account-group-table td:last-child .content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.1rem;
    padding-right: 0.25rem;
}

.btn-dark.sq-btn{
    background: #000;
    padding: 0rem 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 1.175rem;
    width:-moz-fit-content;
    width: fit-content;
}

.purchase-input-text.account-add-group-drop{
    min-height: fit-content !important;
    background: #f0f2f4 !important;
}

.purchase-input-text.account-add-group-drop input.search{
    text-transform: uppercase;
    padding: 0.3rem !important;
    padding-left: 0.9rem !important;
}

.purchase-input-text.account-add-group-drop .dropdown.icon{
    display: flex !important;
    align-items: center;
    height: 100% !important;
    padding: 0 !important;
    padding-right: 0.5rem !important;
}