.id-code-config {
    margin: 1.6rem;
    box-shadow: 0px 0px 6px 0px #00000040;
    background: #FFFFFF8A;
    border-radius: 10px;
}

.code-conf-input-cont {
    font-size: 16px;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 1rem;
}

.purchase-select.code-conf {
    height: 2.6rem !important;
    background: #F5F5F5 !important;
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #D0D0D0
        /* min-height: 2rem !important; */
}

textarea.purchase-select.code-conf{
    height: fit-content !important;
}

.code-conf-checkbox-cont {
    padding: 0.5rem 0.8rem;
    background: white;
    border: 1px solid #C9C9C9;
    border-radius: 11px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.code-conf-checkbox-cont .permission-checkbox{
    width: 1.6rem;
    height: 1.6rem;
}

.code-conf-table-cont{
    background: white;
    padding:0 1.7rem 0.5rem 1.7rem;
    max-height: 17rem;
    overflow: hidden;
    overflow-y: scroll;
}

.code-conf-table-cont table{
    min-width: 100% !important;
    width: 100% !important;
}

.code-conf thead th{
    z-index: 10;
    position: sticky;
    top: 0;
    text-align: start;
    padding-left: 2.5rem;
    color: white;
    background:#4d119b;
    vertical-align: middle;
}

.code-conf tbody tr td{
    text-align: start;
    background: #F5F5F5;
    padding: 0;
    border: none;
    padding-bottom: 5px;
}

.code-conf tbody tr:first-child td{
    padding-top: 5px;
}

.code-conf tbody tr .code-conf-td{
    position: relative;
    background-color: white;
    /* padding: 0.8rem 0; */
    padding-left: 2.4rem;
    display: flex;
    align-items: center;
    height: 2.5rem !important;
}