

.ProdTable thead th {
    padding:0;
    top: 0;
    position: sticky;
    z-index: 3;
    background-color: rgba(77, 48, 114, 1);
    color: white;
    padding-bottom: 10px;
}

/* .ProdTable thead th:first-child {
    border-radius: 7px 0 0 0;
} */
.ProdTable tbody td {
    padding:  .08rem .08rem;
    z-index: 1;
    background-color:white;
}

/* .ProdTable thead th:last-child{
    background-color: #4a00a8;
    border-radius: 0 7px 0 0;
} */

.ProdTable thead tr{
    border-left: 1px solid rgba(77, 48, 114, 1) ;
    border-right: 1px solid rgba(77, 48, 114, 1) ;
}
.ProdTable tbody tr{
    border-bottom: 1px solid rgba(77, 48, 114, 1);
    border-left: .5px solid rgba(77, 48, 114, 1) ;
    border-right: .5px solid rgba(77, 48, 114, 1) ;
}


.ProdTable1 thead th {
    padding:0;
    color: white;
}
.ProdTable1 tbody td {
    padding: .08rem .08rem;
    background-color: white !important;
}
.div-head{
    /* background-color: #51385D; */
    background-color: #51385D;
    color: white;
}
.ProdTable1 thead th{
    /* background-color: #51385D; */
    background-color: #51385D;
    color: white;
}

.ProdTable1 thead th:last-child{
    /* background-color: #51385D; */
    background-color: #51385D;
    color: white;
}

.ProdTable1 thead tr{
    border-left: 1px solid #51385D ;
    border-right: 1px solid #51385D ;
}
.ProdTable1 tfoot tr{
    /* border-top: 1px solid #51385D;
    border-left: 1px solid #51385D ;
    border-right: 1px solid #51385D ; */
    border: 1px solid #51385D;
    padding: 0;
}
.ProdTable1 tbody tr{
    border-bottom: 1px solid #51385D;
    border-left: 1px solid #51385D ;
    border-right: 1px solid #51385D ;
}


.purchase-input-text.table-drop.custom-dropdown-width2{
    min-width: 100% !important; 
    max-width: 100% !important;     
    border-color: white !important;
}

.purchase-input-text.table-drop.custom-dropdown-width2 input{
    text-align: start !important;
}

.ProdTable input{
    padding-left: .5rem;
    background-color: white !important;
    text-align: center;
}

.ProdTable1 input{
    width: '10px';
    height: '10px';
    background-color: white;
    text-align: center;
}

/* .purchase-input-text.table-drop.custom-dropdown-border{
    min-width: 25rem !important; 
    border-color: white !important;
} */
.purchase-input-text.table-drop.custom-dropdown-width1{
    min-width: 25rem !important; 
    /* max-width: 25rem !important; */
    border-color: white !important; 
  }

.ProdTable1 thead tr th{
    position: sticky;
    top: 23.5px;
    z-index: 2;
    color: white;
}

.ProdTable1 tfoot td {
    padding: .08rem .08rem;
    background-color: #6b4a7a;
    color: white !important;
}

.ProdTable1 tfoot tr td{
    position:sticky;
    bottom: 0;
}

.purchase-input-text.table-drop.bordercolors{
    border-color: #dadbdb !important;
}

/* button:focus{
    outline: #0C2D6A;
} */

.bdr{
    border: 2px solid #f08080 !important;
    /* background-color: #f08080 !important; */
}


/*150*/
@media (min-height: 450px){
    .zoom-responsive-view-production{
      zoom: .64;
    } 
  }
  
  /*133*/
  @media (min-height: 500px){
    .zoom-responsive-view-production{
      zoom: .73;
    }
  }
  
  /*120*/
  @media (min-height: 600px){
    .zoom-responsive-view-production{
      zoom: .83;
    }

  }
  
  /*110*/
  @media (min-height: 650px){
    .zoom-responsive-view-production{
      zoom: .92;
    }
  }
  
  /*100%*/
  @media (min-height: 700px){
    .zoom-responsive-view-production{
    zoom: 1.02;
    }
  }
  
  /*90%*/
  @media (min-height: 800px){
    .zoom-responsive-view-production{
      zoom: 1.12;
    }
  }
  
  /* 80% */
  @media (min-height: 900px){
    .zoom-responsive-view-production{
      /* height: 43rem; */
      zoom: 1.26;
    }
  }
  
  /*70*/
  @media (min-height: 1000px){
    .zoom-responsive-view-production{
      zoom: 1.56;
    }
  }