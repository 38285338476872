.gp-trial-bal-gp-total{
    /* border: black solid; */
    box-shadow: 0px 0px 5px black;



}
.gp-trial-bal-gp-total td{
    background-color: rgba(222, 219, 255, 1);
  
}

.group-trial-bt-row td{
    position: sticky;
    bottom: 0;
    background-color:#d2c5e3;
}
/* tyle={{position:"sticky",zIndex:"100",bottom:"0"}} */

