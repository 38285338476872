.printCfg table th td {
    border: 1px solid;
  }

.print-main {
    background-color: blueviolet;
    height: 100vh;
}

.container {
    background-color: aqua;
    height: 80vh;
    width: 100%;
}

.design-box {
    background-color: chartreuse;

}

.tool-box {
    background-color: blue;
    border-left: 2px solid;
    height: 80vh;
}

