.main-page{
    box-shadow: 0px 0px 5px black;
    margin: 20px;
    border-radius: 10px;
}

.radio-box{
    border: solid rgb(180, 180, 180);
    border-radius: 10px;
    padding: 5px;
    width: 200px;
}

input[type="checkbox"].permission-checkbox.boxsize{
    width: 20px;
    height: 20px;
}

button.initialize{
  background-color: rgba(74, 0, 168, 1);
  color: white;
  border: 0;
  width: 100px;
  height: 30px;
}