.pur-p-btn{
background: #4A00A8; 
color: #FFF;
}

.pur-s-btn{
background: rgb(189, 147, 243); 
color: #FFF;
}
.production-head{
    background-color:rgba(77, 48, 114, 1);
    color: #FFF;
}
.production-table{
    width: 100%;
}

.production-table th{
    background-color: rgba(77, 48, 114, 1);
    color: #FFF;
    padding-top:3px;
    padding-bottom: 5px;
}
.production-table tbody td{
    padding-top: 5px;
    padding-bottom: 2px;
    background-color: #F0F4F3;
    border-bottom: 0.1px solid rgba(0, 0, 0, 0.329);

}
.production-table tfoot td{
    background-color: rgb(189, 147, 243);
    padding-bottom: 3px;
    padding-top: 3px;
}


.bg-clr{
    background-color: rgba(77, 48, 114, 1);
    color: #FFF;
}

.bg-clr-1{
    background-color: rgba(77, 48, 114, 1);
    color: #FFF;
}

.production-table-ls thead th{
    color: #FFF;
    padding-top:3px;
    padding-bottom: 5px;
    background-color: rgba(77, 48, 114, 1);
}
.production-table-ls th div{
    background-color: rgba(255, 255, 255, 0.10);
}

.production-table-ls{
    width: 100%;
}

.production-table-ls td{
    background-color: #F0F4F3;
    padding-bottom: 3px;
    padding-top: 3px;
    text-align: center;
}
.production-table-ls tbody tr div{
    border-right: 1px solid black;
}

.pro-table-scroller{
    height: 29rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

.bottom-left{
    border-bottom-left-radius: 10px;
}


.bottom-right{
    border-bottom-right-radius: 10px;
}


/*150*/
@media (min-height: 450px){
    .zoom-responsive-view-production-rep{
      zoom: .64;
    } 
  }
  
  /*133*/
  @media (min-height: 500px){
    .zoom-responsive-view-production-rep{
      zoom: .73;
    }
  }
  
  /*120*/
  @media (min-height: 600px){
    .zoom-responsive-view-production-rep{
      zoom: .83;
    }

  }
  
  /*110*/
  @media (min-height: 650px){
    .zoom-responsive-view-production-rep{
      zoom: .92;
    }
  }
  
  /*100%*/
  @media (min-height: 700px){
    .zoom-responsive-view-production-rep{
    zoom: 1.02;
    }
  }
  
  /*90%*/
  @media (min-height: 800px){
    .zoom-responsive-view-production-rep{
      zoom: 1.12;
    }
  }
  
  /* 80% */
  @media (min-height: 900px){
    .zoom-responsive-view-production-rep{
      /* height: 43rem; */
      zoom: 1.26;
    }
  }
  
  /*70*/
  @media (min-height: 1000px){
    .zoom-responsive-view-production-rep{
      zoom: 1.56;
    }
  }