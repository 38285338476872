.cashbook-table-row th{
    text-align: start;
}
.item_profit_table-bottom{
    background-color: #d2c5e3;
}
.cashbook-table-main1{
    height:33rem;
    overflow: scroll;
}

.cashbook-table-thead-th1{
    background-color: rgba(77, 48, 114, 1);
    color: white;
    position: sticky;
    font-size: medium;
    top: 0;
    z-index: 100;
}

.cashbook-table-thead-th1 th{
    text-align: start;
}

.cash-table-top1{
    width: 100%;
}

.item_profit_table-bottom1{
    text-align: start;
    background-color: rgba(77, 48, 114, 1);
    color: white;
    position: sticky;
    bottom: 0;
}


.cashbook-table-thead-td1{
    background-color: rgb(217, 191, 252);
    color: rgb(0, 0, 0);
    font-size: medium;
}
.cashbook-table-thead-td1 td{
    text-align: start;
    padding-left: 1rem;
}