.op-clr{
    color: red;
}
.table-sd{
    background-color: #d2c5e3;
}

.billwise.table.daybook-table thead tr th{
    z-index: 1;
}

.bill-wise-select {
    min-height: 1.7rem !important;
    background: #eeeeee !important;
}

.bill-wise-select input{
    padding: 0.3rem 1rem !important;
}

.bill-wise-select .icon{
    padding: 0 !important;
    margin-right: 0.1rem !important;
    height: 1.7rem !important;
    display: flex;
    align-items: center;
}
.table-scrolle{
    height: 34rem;
    overflow-y: auto;
}

.bill-wise-table thead tr th{
    background: rgba(77, 48, 114, 1);
    position: sticky;
    top: 0;
    color: white;
}